import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";

import { IconRightArrow } from "utils/icons";

import { Loader } from "samespace-zen";
import { client } from "graphql/Provider";

import { Avatar, AuthenticationOptions } from "components";
import { randomColorGenerator } from "../../utils/common";

import { CHECK_INVITED_USER, COMPLETE_SPACE_INVITE } from "./queries";

import UserSetupForm from "./UserSetupForm";
import Header from "./Header";

import jwt_decode from "jwt-decode";

import { motion, AnimatePresence } from "framer-motion";
import { setCookie } from "utils/cookies";

const Invite = () => {
  const [step, setStep] = useState(false);
  const [inviteId, setInviteId] = useState("");
  const [orgName, setOrgName] = useState("");
  const params = useParams();
  const [orgId, setOrgId] = useState("");
  const [invitePayload, setInvitePayload] = useState();
  const [completeSpaceInvite] = useMutation(COMPLETE_SPACE_INVITE, {
    client,
    context: {
      headers: {
        Authorization: `${params.token}`,
      },
    },
  });
  const { data, loading } = useQuery(CHECK_INVITED_USER, {
    client,
    context: {
      headers: {
        Authorization: `${params.token}`,
      },
    },
  });
  useEffect(() => {
    setInviteId(jwt_decode(params.token).inviteId);
    setOrgName(jwt_decode(params.token).orgName);
    setOrgId(jwt_decode(params.token).orgId);

    if (!data?.checkInvitedUser?.email) {
      setStep(1);
      setInvitePayload({
        checkInvitedUser: {
          email: jwt_decode(params.token).email,
        },
      });
    } else {
      setInvitePayload(data);
      setStep(false);
    }
  }, [params.token, data]);
  const stepHandler = useCallback((step) => {
    setStep(step);
  }, []);
  const directLoginHandler = useCallback(() => {
    completeSpaceInvite().then(({ data }) => {
      const accounts = data.completeSpaceInvite.accounts;
      const user = data.completeSpaceInvite.user;
      const sessionId = data.completeSpaceInvite.sessionId;
      setCookie("firstName", user.firstName);
      setCookie("lastName", user.lastName);
      setCookie("userToken", user.userToken);
      setCookie("centralUserId", user.userId);
      setCookie("sessionId", sessionId);
      const organizations = {};
      accounts.forEach((acc) => {
        organizations[acc.orgId] = {
          name: acc.orgName,
          spaces: acc.spaces.map((space) => {
            return {
              instanceId: space.instanceId,
              name: space.spaceName,
              spaceId: space.spaceId,
              productName: space.productName,
            };
          }),
        };
      });
      let activeOrg;
      if (data.completeSpaceInvite.orgId) {
        activeOrg = data.completeSpaceInvite.orgId;
      } else {
        activeOrg = Object.keys(organizations)[0];
      }
      setCookie("organizations", JSON.stringify(organizations));
      setCookie("activeOrg", activeOrg);
      setCookie("activeSpace", organizations[activeOrg].spaces[0].name);
      if (
        organizations[activeOrg].spaces.filter(
          (item) => item.productName === "Samespace"
        ).length > 0
      ) {
        window.location.href = `https://${
          process.env.REACT_APP_BASE_DOMAIN || "engage"
        }.${process.env.REACT_APP_BASE_API || "ss.dev"}`;
      } else {
        window.location.href = `https://work.${
          process.env.REACT_APP_BASE_API || "ss.dev"
        }`;
      }
    });
  }, [completeSpaceInvite]);
  return (
    <AnimatePresence exitBeforeEnter>
      {loading && (
        <div className=" w-full h-screen flex items-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && <Header />}

      {!loading && step && (
        <motion.div
          initial={{
            x: 100,
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            x: 0,
            transition: { duration: 0.4, ease: "easeOut" },
          }}
          exit={{
            x: -100,
            opacity: 0,
            transition: { duration: 0.4, ease: "easeIn" },
          }}
          className="flex items-center justify-center w-full h-full mt-12"
        >
          <div className="w-[480px] flex items-center justify-center flex-col">
            <div className="flex justify-center mb-[32px] w-full">
              <div className="text-[20px] text-[#333333] text-center font-semibold">
                Join {orgName}
              </div>
            </div>
            <AuthenticationOptions
              invitePage
              type="signup"
              name={orgName}
              inviteId={inviteId}
            />
            <UserSetupForm orgId={orgId} invitePayload={invitePayload} />
          </div>
        </motion.div>
      )}

      <div className="flex items-center flex-col justify-center">
        {!loading && data.checkInvitedUser && !step && (
          <motion.div
            initial={{
              x: 100,
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { duration: 0.4, ease: "easeOut" },
            }}
            exit={{
              x: -100,
              opacity: 0,
              transition: { duration: 0.4, ease: "easeIn" },
            }}
            className="w-[480px] flex flex-col items-center justify-center mt-[40px]"
          >
            <div className="text-[32px] text-[#333333] font-semibold mb-[40px]">
              {`Join ${orgName}`}
            </div>
            <div className="w-[480px]">
              <div className="text-sm opacity-60 text-left mb-2">
                Continue With
              </div>
              <div className="org-list">
                <div
                  className="org-item flex items-center"
                  onClick={directLoginHandler}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <Avatar
                        label={data?.checkInvitedUser?.first_name.substring(
                          0,
                          1
                        )}
                        color={randomColorGenerator(
                          Math.floor(Math.random() * 6)
                        )}
                        size="small"
                      />
                      <div className="flex items-start flex-col ml-2">
                        <div className="font-bold flex items-center">
                          {data.checkInvitedUser?.first_name +
                            " " +
                            data?.checkInvitedUser?.last_name}
                        </div>
                        <div className="text-sm opacity-60">
                          {data?.checkInvitedUser?.email}
                        </div>
                      </div>
                    </div>
                    <div className="opacity-60">{IconRightArrow}</div>
                  </div>
                </div>
              </div>
              <div className="w-full or-divider my-[28px] relative">
                <div className="or-text">OR</div>
              </div>
              <div
                className="org-list mt-2"
                onClick={stepHandler.bind(null, 1)}
              >
                <div className="org-item font-medium flex items-center justify-between w-full">
                  <div>Create New Account</div>
                  <div className="opacity-60">{IconRightArrow}</div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </AnimatePresence>
  );
};

export default Invite;
