import React, { useState, useCallback } from "react";
import classNames from "classnames";

export const Avatar = ({ label, size, color, userId }) => {
  const [showAvatar, setShowAvatar] = useState(false);
  const imageErrorHandler = useCallback(() => {
    setShowAvatar(true);
  }, []);
  return (
    <>
      {!showAvatar && userId && (
        <img
          alt=""
          id={userId}
          onError={imageErrorHandler}
          src={`https://login.${
            process.env.REACT_APP_BASE_API || "samespace.com"
          }/api/profile/people/${userId}`}
          className={classNames(
            "flex items-center justify-center rounded-full bg-opacity-10 text-skin-primary object-cover",
            {
              "h-[20px] w-[20px]": size === "xsmall",
              "h-[32px] w-[32px]": size === "small",
              "h-[40px] w-[40px]": size === "regular",
              "h-[48px] w-[48px]": size === "large",
              "h-full w-full": !size,
            }
          )}
        />
      )}
      {(showAvatar || !userId) && (
        <div
          style={{
            backgroundColor: `rgba(${color}, 0.1)`,
          }}
          className={classNames(
            "flex items-center justify-center rounded-full bg-opacity-10 text-skin-primary",
            {
              "h-[20px] w-[20px]": size === "xsmall",
              "h-[32px] w-[32px]": size === "small",
              "h-[40px] w-[40px]": size === "regular",
              "h-[48px] w-[48px]": size === "large",
              "h-full w-full": !size,
            }
          )}
        >
          <div
            style={{
              color: `rgb(${color})`,
            }}
          >
            {label}
          </div>
        </div>
      )}
    </>
  );
};
