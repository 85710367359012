import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import zxcvbn from "zxcvbn";

import { Input, Button, toast, Select } from "samespace-zen";
import { COMPLETE_SPACE_INVITE } from "./queries";
import { emailRegex } from "../../utils/common";
import { IconEye } from "../../utils/icons";

import { client } from "graphql/Provider";
import { setCookie } from "utils/cookies";

const UserSetupForm = ({ invitePayload, orgId }) => {
  const params = useParams();
  const [type, setType] = useState("password");
  const [strength, setStrength] = useState(0);
  const [btnStates, setBtnStates] = useState({
    success: false,
    loading: false,
    failed: false,
  });
  const [payload, setPayload] = useState({
    firstName: invitePayload?.checkInvitedUser?.first_name || "",
    lastName: invitePayload?.checkInvitedUser?.last_name || "",
    password: "",
    email: invitePayload?.checkInvitedUser?.email || "",
    workProfile: "",
  });
  const disabledButton = useCallback(() => {
    if (
      payload?.password?.length >= 8 &&
      payload.firstName &&
      payload.lastName &&
      payload.email.match(emailRegex) &&
      payload.workProfile
    ) {
      return false;
    } else {
      return true;
    }
  }, [payload]);
  const passwordTypeHandler = useCallback(() => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  }, [type]);
  const [completeInvite] = useMutation(COMPLETE_SPACE_INVITE, {
    client,
    context: {
      headers: {
        Authorization: `${params.token}`,
      },
    },
  });
  const onChangeHandler = useCallback(
    (e) => {
      if (e.target.name === "password") {
        setStrength(zxcvbn(e.target.value).score);
      }
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
      });
    },
    [payload]
  );

  const submitFormHandler = useCallback(() => {
    setBtnStates({
      ...btnStates,
      loading: true,
    });
    completeInvite({
      variables: {
        payload: {
          ...payload,
          workProfile: payload.workProfile.value,
        },
      },
    })
      .then(({ data }) => {
        setBtnStates({
          ...btnStates,
          success: true,
        });
        const accounts = data.completeSpaceInvite.accounts;
        const user = data.completeSpaceInvite.user;
        const sessionId = data.completeSpaceInvite.sessionId;
        setCookie("firstName", user.firstName);
        setCookie("lastName", user.lastName);
        setCookie("userToken", user.userToken);
        setCookie("centralUserId", user.userId);
        setCookie("sessionId", sessionId);
        const organizations = {};
        accounts.forEach((acc) => {
          organizations[acc.orgId] = {
            name: acc.orgName,
            spaces: acc.spaces.map((space) => {
              return {
                instanceId: space.instanceId,
                name: space.spaceName,
                spaceId: space.spaceId,
                productName: space.productName,
              };
            }),
          };
        });
        let activeOrg;
        if (data.completeSpaceInvite.orgId) {
          activeOrg = data.completeSpaceInvite.orgId;
        } else {
          activeOrg = Object.keys(organizations)[0];
        }
        setCookie("organizations", JSON.stringify(organizations));
        setCookie("activeOrg", activeOrg);
        setCookie("activeSpace", organizations[activeOrg].spaces[0].name);
        if (
          organizations[activeOrg].spaces.filter(
            (item) => item.productName === "Samespace"
          ).length > 0
        ) {
          setTimeout(() => {
            window.location.href = `https://${
              process.env.REACT_APP_BASE_DOMAIN || "ss.dev"
            }.${process.env.REACT_APP_BASE_API || "ss.dev"}`;
          }, 1300);
        } else {
          setTimeout(() => {
            window.location.href = `https://work.${
              process.env.REACT_APP_BASE_API || "ss.dev"
            }`;
          }, 1300);
        }
      })
      .catch((e) => {
        toast.error(e?.message || "Something Went Wrong");
        setBtnStates({
          ...btnStates,
          failed: true,
          loading: false,
        });
        setTimeout(() => {
          setBtnStates({
            ...btnStates,
            failed: false,
          });
        }, 2000);
      });
  }, [payload, btnStates, completeInvite]);
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!disabledButton()) {
        if (e.keyCode === 13) {
          submitFormHandler();
        }
      }
    };

    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [submitFormHandler, orgId, disabledButton]);
  return (
    <div className="flex flex-col items-center justify-center w-[480px] pb-[32px]">
      <div className="text-left w-full flex flex-col gap-[40px]">
        <div className="grid grid-cols-2 gap-x-4">
          <Input
            autoFocus
            label="First Name"
            name="firstName"
            value={payload.firstName}
            onChange={onChangeHandler}
          />
          <Input
            label="Last Name"
            name="lastName"
            value={payload.lastName}
            onChange={onChangeHandler}
          />
        </div>
        <Input
          disabled
          name="email"
          label="Email"
          value={payload.email}
          onChange={onChangeHandler}
        />
        <Select
          label="What do you do?"
          name="workProfile"
          value={payload.workProfile}
          onChange={onChangeHandler}
          options={[
            {
              label: "Software Engineer",
              value: "Software Engineer",
            },
            {
              label: "Support Engineer",
              value: "Support Engineer",
            },
            {
              value: "Accounts & Finance",
              label: "Accounts & Finance",
            },
            {
              value: "Marketing",
              label: "Marketing",
            },
          ]}
        />
        <div>
          <Input
            label="Password"
            name="password"
            type={type}
            suffix={
              <div
                onClick={passwordTypeHandler}
                className="flex items-center cursor-pointer"
              >
                {IconEye}
              </div>
            }
            value={payload.password}
            onChange={onChangeHandler}
          />
          <div className="password-hint flex items-center justify-between">
            <span className="text-sm opacity-60">
              Alphanumeric with min. 8 characters.
            </span>
            {payload?.password?.length >= 8 && (
              <span className={`text-sm password-${strength}`}>
                {strength === 0 ? "Bad" : ""}
                {strength === 1 ? "Weak" : ""}
                {strength === 2 ? "Good" : ""}
                {strength === 3 ? "Very Good" : ""}
                {strength === 4 ? "Excellent" : ""}
              </span>
            )}
          </div>
        </div>
        <Button
          full
          shape="rounded"
          disabled={disabledButton()}
          loading={btnStates.loading}
          success={btnStates.success}
          failure={btnStates.failed}
          title="Join"
          onClick={submitFormHandler}
        />
      </div>
    </div>
  );
};

export default UserSetupForm;
