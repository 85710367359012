import { gql } from "@apollo/client";

export const CHECK_INVITED_USER = gql`
  query checkInvitedUser {
    checkInvitedUser
  }
`;

export const COMPLETE_SPACE_INVITE = gql`
  mutation CompleteSpaceInvite($payload: CompleteSpaceInviteInput) {
    completeSpaceInvite(payload: $payload)
  }
`;
