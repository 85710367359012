import { useCallback, useState } from "react";
import { Button, Input, Text } from "samespace-zen";
import { withFormik } from "formik";
import * as Yup from "yup";

import { IconSamespace } from "utils/icons";

import { useMutation } from "@apollo/client";

import { IconEmailSuccess } from "utils/icons";
import { FORGOT_PASSWORD } from "./queries";

import { toast } from "react-hot-toast";

const ForgotPasswordForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  const [btnStates, setBtnStates] = useState({
    success: true,
    failure: false,
    pending: false,
  });
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const [formPhase, setFormPhase] = useState(1);
  const submitFormHandler = useCallback(() => {
    setBtnStates({
      ...btnStates,
      loading: true,
    });
    forgotPassword({
      variables: {
        email: values.email,
      },
    })
      .then((res) => {
        setBtnStates({
          ...btnStates,
          loading: false,
          success: true,
        });
        setTimeout(() => {
          setFormPhase(2);
        }, 1300);
      })
      .catch((e) => {
        toast.error(e?.message || "Something Went Wrong", {
          position: "bottom-right",
        });
        setBtnStates({
          ...btnStates,
          failed: true,
          loading: false,
        });
        setTimeout(() => {
          setBtnStates({
            ...btnStates,
            failed: false,
          });
        }, 2000);
      });
  }, [values, forgotPassword, btnStates]);
  return (
    <div className="w-[400px] text-left flex items-center justify-center flex-col">
      <div className="mb-[32px]">{IconSamespace}</div>
      {formPhase === 1 && (
        <>
          <Input
            autoFocus
            type="text"
            name="email"
            label="Email"
            value={values.email}
            errorText={errors.email}
            error={touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="mt-[32px] w-full">
            <Button
              shape="rounded"
              onClick={submitFormHandler}
              full
              title="Send Reset Link"
            />
          </div>
        </>
      )}
      {formPhase === 2 && (
        <div className="flex flex-col items-center justify-center text-center">
          {IconEmailSuccess}
          <Text
            label="Email Sent"
            size="2xl"
            weight="medium"
            className="mb-2 mt-4"
          />

          <Text
            label="Thanks, check your email for instructions to reset your password"
            color="muted"
            size="sm"
            className="w-[320px]"
          />
          <div className="flex mt-4">
            <Text
              className="hidden sm:inline-flex"
              label="Didn’t see an email?"
              color="muted"
              size="sm"
            />
            &nbsp;
            <Text
              size="sm"
              color="primary"
              label="Resend"
              className="hover:underline cursor-pointer"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withFormik({
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    email: Yup.string().email("Enter a valid Email").required("Required"),
  }),
})(ForgotPasswordForm);
