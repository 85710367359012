import { useCallback } from "react";
import { IconLinkedIn, IconApple, IconGoogle } from "../utils/icons";

import { SIGN_IN_WITH_OAUTH } from "../pages/Home/queries";

import { useMutation } from "@apollo/client";

import { toast } from "react-hot-toast";

export const AuthenticationOptions = ({ type, inviteId, name, invitePage }) => {
  const [signInWithOAuth] = useMutation(SIGN_IN_WITH_OAUTH);
  const oAuthSignUpHandler = useCallback(
    (provider) => {
      signInWithOAuth({
        variables: {
          payload: {
            provider: provider,
            inviteId: inviteId,
          },
        },
      })
        .then(({ data }) => {
          window.location = data.signInWithOAuth;
        })
        .catch((e) => {
          toast.error(e.message || "Something went wrong!");
        });
    },
    [inviteId, signInWithOAuth]
  );
  return (
    <div className={`${invitePage ? "w-[500px]" : "w-[400px]"}`}>
      <div className="grid grid-cols-3 gap-x-[12px]">
        <div
          className="w-full h-[44px] social-auth flex items-center justify-center cursor-pointer mr-[16px] rounded-full"
          onClick={oAuthSignUpHandler.bind(null, "LINKEDIN")}
        >
          <span>{IconLinkedIn}</span>
        </div>

        <div
          className="w-full h-[44px] social-auth flex items-center justify-center cursor-pointer mr-[16px] rounded-full"
          onClick={oAuthSignUpHandler.bind(null, "GOOGLE")}
        >
          <span>{IconGoogle}</span>
        </div>

        <div
          className="w-full h-[44px] social-auth flex items-center justify-center cursor-pointer rounded-full"
          onClick={oAuthSignUpHandler.bind(null, "APPLE")}
        >
          <span>{IconApple}</span>
        </div>
      </div>
      <div className="w-full or-divider mt-[48px] mb-[32px] relative">
        <div className="or-text">OR</div>
      </div>
    </div>
  );
};
