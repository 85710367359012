import React from "react";

import WorkspaceLogo from "assets/Images/workspaceLogo.svg";

const Header = () => {
  return (
    <nav className="relative flex items-center justify-center setup-pages-header w-full">
      <img src={WorkspaceLogo} alt="Workspace" />
    </nav>
  );
};

export default Header;
