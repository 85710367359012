import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import OAuth from "pages/OAuth";
import ForgotPassword from "pages/ForgotPassword";
import SSOLogin from "pages/SSOLogin";
import Invite from "pages/Invite";
import Helmet from "react-helmet";

import { Toaster } from "react-hot-toast";
import ResetPassword from "pages/ResetPassword";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="AI-Powered Sales Acceleration & Customer Engagement Platform."
        />
        <title>Samespace-Login</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/oauth/redirect" element={<OAuth />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/sso/authorize" element={<SSOLogin />} />
          <Route path="/invite/:token" element={<Invite />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </>
  );
}

export default App;
