import { useState, useCallback } from "react";
import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { Input, Button } from "samespace-zen";

import { COMPLETE_FORGOT_PASSWORD } from "./queries";

import { toast } from "react-hot-toast";
import { IconSamespace } from "utils/icons";

const ResetPasswordForm = () => {
  const params = useParams();
  const [resetPassword] = useMutation(COMPLETE_FORGOT_PASSWORD, {
    context: {
      headers: {
        Authorization: `${params.token}`,
      },
    },
  });
  const [btnStates, setBtnStates] = useState({
    success: false,
    loading: false,
    failed: false,
  });
  const [payload, setPayload] = useState({
    pass: "",
    confirmPass: "",
  });
  const onChangeHandler = useCallback(
    (e) => {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
      });
    },
    [payload]
  );

  const disabledButton = useCallback(() => {
    if (
      payload.pass.length > 0 &&
      String(payload.pass).valueOf() === String(payload.confirmPass).valueOf()
    ) {
      return false;
    }
    return true;
  }, [payload]);

  const submitFormHandler = useCallback(() => {
    setBtnStates({
      ...btnStates,
      loading: true,
    });
    resetPassword({
      variables: {
        password: payload.pass,
      },
    })
      .then(({ data }) => {
        setBtnStates({
          ...btnStates,
          loading: false,
          success: true,
        });
      })
      .catch((e) => {
        toast.error(e?.message || "Something Went Wrong");
        setBtnStates({
          ...btnStates,
          failed: true,
          loading: false,
        });
        setTimeout(() => {
          setBtnStates({
            ...btnStates,
            failed: false,
          });
        }, 2000);
      });
  }, [payload, btnStates, resetPassword]);
  return (
    <div className="w-[400px] text-left flex flex-col justify-center items-center gap-y-[32px]">
      <div>{IconSamespace}</div>
      <Input
        autoFocus
        value={payload.pass}
        name="pass"
        type="password"
        label="Password"
        className="mb-2"
        onChange={onChangeHandler}
      />
      <Input
        value={payload.confirmPass}
        name="confirmPass"
        type="password"
        label="Confirm Password"
        onChange={onChangeHandler}
      />
      <Button
        full
        successText="Logging In..."
        disabled={disabledButton()}
        loading={btnStates.loading}
        success={btnStates.success}
        failure={btnStates.failed}
        title="Reset Password"
        onClick={submitFormHandler}
      />
    </div>
  );
};
export default ResetPasswordForm;
