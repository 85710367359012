import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { withFormik } from "formik";
import { AuthenticationOptions, Avatar } from "components";

import { Input, Text, Button } from "samespace-zen";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { SIGNIN, SSO_LOGIN } from "./queries";
import { setCookie } from "utils/cookies";

import { validateEmail } from "utils/common";

import { client } from "graphql/Provider";

import { toast } from "react-hot-toast";

import { IconRightArrow, IconSamespace } from "utils/icons";

import { randomColorGenerator } from "utils/common";

import { AnimatePresence, motion } from "framer-motion";

const LoginForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  validateForm,
  setTouched,
  setErrors,
}) => {
  const navigate = useNavigate();
  const [pageLoaded, setPageLoaded] = useState();
  const [btnStates, setBtnStates] = useState({
    loading: false,
    success: false,
    failure: false,
  });
  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 300);
  }, []);
  const [SAMLOrgs, setSAMLOrgs] = useState([]);
  const [ssoLoading, setSSOLoading] = useState(false);
  const [showSAMLForm, setShowSAMLForm] = useState(false);
  const [SiginIn] = useMutation(SIGNIN);
  const ssoLoginHandler = useCallback(
    async (e) => {
      e.preventDefault();
      setSSOLoading(true);
      if (validateEmail(values.email)) {
        try {
          const { data } = await client.query({
            query: SSO_LOGIN,
            variables: {
              email: values.email,
            },
          });
          if (data.ssoLogin) {
            if (Array.isArray(data.ssoLogin) && data.ssoLogin.length === 1) {
              window.location.href = data.ssoLogin[0].ssoUrl;
            } else {
              setSAMLOrgs(data.ssoLogin);
            }
          } else {
            toast.error("SSO Login is not enabled for your organization.", {
              position: "bottom-right",
            });
          }
        } catch (e) {
          setSSOLoading(false);
          toast.error(e.message || "Something went wrong", {
            position: "bottom-right",
          });
        }
      }
    },
    [values]
  );
  const redirectHandler = useCallback(() => {
    navigate("/forgot-password");
  }, [navigate]);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      validateForm().then(async (errors) => {
        setTouched({ ...errors });
        setErrors({ ...errors });
        if (Object.keys(errors).length === 0) {
          setBtnStates({
            ...btnStates,
            loading: true,
          });
          try {
            const { data } = await SiginIn({
              variables: {
                email: values.email,
                password: values.password,
              },
            });
            const accounts = data.signin.accounts;
            const user = data.signin.user;
            const sessionId = data.signin.sessionId;
            setCookie("firstName", user.firstName);
            setCookie("lastName", user.lastName);
            setCookie("userToken", user.userToken);
            setCookie("centralUserId", user.userId);
            setCookie("sessionId", sessionId);
            const organizations = {};
            accounts.forEach((acc) => {
              organizations[acc.orgId] = {
                name: acc.orgName,
                spaces: acc.spaces.map((space) => {
                  return {
                    instanceId: space.instanceId,
                    name: space.spaceName,
                    spaceId: space.spaceId,
                    productName: space.productName,
                  };
                }),
              };
            });
            setCookie("organizations", JSON.stringify(organizations));
            const activeOrg = Object.keys(organizations)[0];
            setCookie("activeOrg", activeOrg);
            setCookie("activeSpace", organizations[activeOrg].spaces[0].name);
            if (
              organizations[activeOrg].spaces.filter(
                (item) => item.productName === "Samespace"
              ).length > 0
            ) {
              window.location.href = `https://${
                process.env.REACT_APP_BASE_DOMAIN || "engage"
              }.${process.env.REACT_APP_BASE_API || "ss.dev"}`;
            } else {
              window.location.href = `https://work.${
                process.env.REACT_APP_BASE_API || "ss.dev"
              }`;
            }
          } catch (e) {
            toast.error(e.message || "Something went wrong", {
              position: "bottom-right",
            });
            setBtnStates({
              ...btnStates,
              failure: true,
              loading: false,
            });
            setTimeout(() => {
              setBtnStates({
                ...btnStates,
                failure: false,
                loading: false,
              });
            }, 3000);
          }
        }
      });
    },
    [validateForm, values, SiginIn, setErrors, setTouched, btnStates]
  );
  return (
    <AnimatePresence exitBeforeEnter>
      <form onSubmit={submitForm} className="flex flex-col items-center">
        <div className="mb-[32px]">{IconSamespace}</div>

        {SAMLOrgs.length > 0 && (
          <motion.div
            key="saml-orgs"
            initial={{
              opacity: 0,
              x: 100,
            }}
            animate={{
              x: 0,
              opacity: 1,

              transition: { duration: 0.4, ease: "easeIn" },
            }}
            exit={{
              x: 100,
              opacity: 0,
              transition: { duration: 0.4, ease: "easeIn" },
            }}
          >
            <div className="text-sm opacity-60 mb-2 text-left">
              Continue With
            </div>
            <div className="w-[400px] org-list max-h-[600] overflow-y-auto">
              {SAMLOrgs.map((item) => {
                return (
                  <div
                    className="org-item w-full justify-between"
                    onClick={() => (window.location.href = item.ssoUrl)}
                  >
                    <div className="flex flex-col items-start">
                      <div>
                        <div className="flex items-center">
                          <Avatar
                            size="regular"
                            label={item.orgName.substring(0, 1)}
                            color={randomColorGenerator(
                              Math.floor(Math.random() * 6)
                            )}
                          />
                          <div className="flex flex-col items-start ml-2">
                            <div className="font-bold text-base">
                              {item.orgName}
                            </div>
                            <div className="text-sm opacity-60">
                              {item.orgId}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-60">{IconRightArrow}</div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
        {SAMLOrgs.length === 0 && (
          <AnimatePresence exitBeforeEnter>
            {showSAMLForm && (
              <motion.div
                key="saml-form"
                initial={{
                  x: -100,
                  opacity: 0,
                }}
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.4, ease: "easeIn" },
                }}
                exit={{
                  x: -100,
                  opacity: 0,
                  transition: { duration: 0.4, ease: "easeIn" },
                }}
              >
                <div className="w-[400px] text-left mb-[32px] flex flex-col items-center justify-center">
                  <div className="w-[400px] text-left mt-[32px]">
                    <Input
                      autoFocus
                      type="text"
                      name="email"
                      label="Email"
                      value={values.email}
                      errorText={errors.email}
                      error={touched.email && errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-col items-center">
                  <Button
                    full
                    shape="rounded"
                    loading={ssoLoading}
                    disabled={!validateEmail(values.email)}
                    onClick={ssoLoginHandler}
                    className="mt-2"
                    title="Continue with SAML SSO"
                  />
                  <Button
                    full
                    shape="rounded"
                    disabled={ssoLoading}
                    onClick={() => {
                      setShowSAMLForm(false);
                    }}
                    className="mt-2"
                    accent="contrast"
                    type="ghost"
                    title="← Back To Login"
                  />
                </div>
              </motion.div>
            )}
            {!showSAMLForm && (
              <motion.div
                key="login-form"
                initial={
                  !pageLoaded
                    ? {
                        opacity: 0,
                      }
                    : {
                        x: 100,
                        opacity: 0,
                      }
                }
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: { duration: 0.4, ease: "easeIn" },
                }}
                exit={{
                  x: 100,
                  opacity: 0,
                  transition: { duration: 0.4, ease: "easeIn" },
                }}
              >
                <AuthenticationOptions type="sign in" />
                <div className="w-[400px] text-left mb-[32px]">
                  <Input
                    autoFocus
                    type="text"
                    name="email"
                    label="Email"
                    value={values.email}
                    errorText={errors.email}
                    error={touched.email && errors.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="w-[400px] text-left mb-[32px] relative">
                  <span className="absolute right-0 cursor-pointer z-10">
                    <Text
                      onClick={redirectHandler}
                      size="xs"
                      color="primary"
                      label="Forgot?"
                      className="hover:underline"
                    />
                  </span>
                  <Input
                    name="password"
                    label="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    errorText={errors.password}
                    error={touched.password && errors.password}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="w-full flex flex-col items-center">
                  <Button
                    title="Sign In"
                    full
                    shape="rounded"
                    loading={btnStates.loading}
                    success={btnStates.success}
                    failure={btnStates.failure}
                  />
                  <Button
                    full
                    shape="rounded"
                    onClick={() => {
                      setShowSAMLForm(true);
                    }}
                    className="mt-2"
                    accent="contrast"
                    type="ghost"
                    title="Continue with SAML SSO"
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        )}
        <div className="absolute bottom-4">
          <Text
            label="Copyright © 2022 Samespace Inc."
            color="muted"
            size="xs"
          />
        </div>
      </form>
    </AnimatePresence>
  );
};

export default withFormik({
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    email: Yup.string().email("Enter a valid Email").required("Required"),
    password: Yup.string().required("Required"),
  }),
})(LoginForm);
