import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { setCookie } from "utils/cookies";
import { GET_SESSION_DETAILS } from "../OAuth/queries";

import jwt_decode from "jwt-decode";

export const SSOAuthorize = () => {
  const params = new URLSearchParams(window.location.search);
  const { data } = useQuery(GET_SESSION_DETAILS, {
    variables: {
      id: jwt_decode(params.get("token")).sessionId,
    },
  });

  useEffect(() => {
    const Login = async () => {
      const orgDetails = jwt_decode(params.get("token"));
      const accounts = data.getSessionDetails.accounts;
      const user = data.getSessionDetails.user;
      const sessionId = jwt_decode(params.get("token")).sessionId;
      setCookie("firstName", user.firstName);
      setCookie("lastName", user.lastName);
      setCookie("userToken", user.userToken);
      setCookie("centralUserId", user.userId);
      setCookie("sessionId", sessionId);
      const organizations = {};
      accounts.forEach((acc) => {
        organizations[acc.orgId] = {
          name: acc.orgName,
          spaces: acc.spaces.map((space) => {
            return {
              instanceId: space.instanceId,
              name: space.spaceName,
              spaceId: space.spaceId,
              productName: space.productName,
            };
          }),
        };
      });
      setCookie("organizations", JSON.stringify(organizations));
      const activeOrg = orgDetails.activeOrg;
      setCookie("activeOrg", activeOrg);
      setCookie("activeSpace", organizations[activeOrg].spaces[0].name);
      if (
        organizations[activeOrg].spaces.filter(
          (item) => item.productName === "Samespace"
        ).length > 0
      ) {
        window.location.href = `https://${
          process.env.REACT_APP_BASE_DOMAIN || "ss.dev"
        }.${process.env.REACT_APP_BASE_API || "ss.dev"}`;
      } else {
        window.location.href = `https://work.${
          process.env.REACT_APP_BASE_API || "ss.dev"
        }`;
      }
    };
    if (data) {
      Login();
    }
  }, [data, params]);
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <svg
        class=" h-8 w-8 animate-spin text-accent"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
};

export default SSOAuthorize;
