export const BASE_SAMESPACE_API = ".samespace.com/auth/api/accounts";

export const avatarColors = [
  "124,58,237",
  "219,39,119",
  "79,70,229",
  "37,99,235",
  "5,150,105",
  "217,119,6",
  "234,88,12",
  "13,148,136",
  "8,145,178",
  "225,29,72",
];
